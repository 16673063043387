import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

// 获取模块绑定管理列表
export const modularProjectList = (size, page, data) => {
  const submitData = {
    '$paging.size': size,
    '$filter.name': 'null',
    '$paging.page': page,
    '$orderby': 'null',
    '$filter.params': data,
  };
  return fetchApi(URL.MODULAR_PROJECT_LIST, submitData)
}

// 新增模块
export const addModuleBinding = (data) => {
  return fetchApi(URL.ADD_MODULE_BINDING, data, 'post', 'data')
}

// 编辑
export const editModuleBinding = (data) => {
  return fetchApi(URL.EDIT_MODULE_BINDING, data, 'post', 'data')
}

// 项目下拉
export const projectList = () => {
  return fetchApi(URL.BINDING_PROJECT_LIST, null)
}

// 模块名称下拉
export const selectModularInfoList = (data) => {
  return fetchApi(URL.SELECT_MODULAR_INFO_LIST, data, 'post', 'data')
}

// 模块管理详情
export const selectModularProjectById = (id) => {
  return fetchApi(URL.SELECT_MODULAR_PROJECT_BYID, id)
}

// 删除
export const deleteModularProject = (id) => {
  return fetchApi(URL.DELETE_MODULAR_PROJECT, id)
}

// 启用或禁用
export const enableOrDisable = (data) => {
  return fetchApi(URL.ENABLE_DISABLE, data, 'post', 'data')
}

// 魔方编号
export const rubikByTitleNumber = (data) => {
  return fetchApi(URL.RUBIK_BYTITLE_NUMBER, data)
} 
