<template>
  <div class="moduleBindingManage">
    <ds-header title="模块绑定管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="项目名称：">
        <a-select
          v-model="params.projectName"
          class="searchInput"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          placeholder="请选择"
        >
          <a-select-option v-for="option in projectName" :key="option.id" :value="option.id">{{
            option.project_name
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="模块名称：">
        <a-select
          v-model="params.moduleName"
          class="searchInput"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          @change="handleChange"
        >
          <a-select-option v-for="option in moduleName" :key="option.id" :value="option.id">{{
            option.moduleTitle
          }}</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="状态：">
        <a-select
          v-model="params.state"
          class="searchInput"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in state" :key="option.id" :value="option.id">{{
            option.value
          }}</a-select-option>
        </a-select>
      </a-form-item>

      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="add()"> <a-icon type="plus" /> 添加 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button
            type="link"
            class="btnClass"
            :disabled="record.moduleState == 1"
            :class="{ inputColor: record.moduleState == 1 }"
            @click="edit(record)"
          >
            编辑
          </a-button>
          <a-button type="link" class="btnClass" @click="disableorEnabled(text)">
            {{ text.moduleState == 1 ? "禁用" : "启用" }}
          </a-button>
          <a-button
            type="link"
            class="btnClass"
            :disabled="record.moduleState == 1"
            :class="{ inputColor: record.moduleState == 1 }"
            @click="deleteById(record)"
          >
            删除
          </a-button>
        </span>
      </a-table>
    </div>
    <a-modal v-model="visibleFloor" :title="title" :footer="null" :maskClosable="false" :closable="false">
      <a-form-model layout="inline" ref="ruleForm" :model="submit" :rules="rules">
        <a-form-model-item label="选择项目：" prop="projectId">
          <a-select
            v-model="submit.projectId"
            class="inputClass"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            @change="handleChangeProject"
          >
            <a-select-option v-for="option in projectName" :key="option.id" :value="option.id">{{
              option.project_name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="模块名称：" prop="modularId">
          <a-select
            v-model="submit.modularId"
            class="inputClass"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            @change="handleChange"
          >
            <a-select-option v-for="option in moduleName" :key="option.id" :value="option.id">{{
              option.moduleTitle
            }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="魔方选择：" prop="rubikId" v-if="this.rubikcubeVisble">
          <a-select
            show-search
            placeholder="请输入魔方名称或编号"
            option-filter-prop="children"
            class="inputClass"
            :filter-option="filterOption"
            v-model="submit.rubikId"
          >
            <a-select-option v-for="item in rubikNumber" :key="item.id" :value="item.id">
              {{ item.rubikTitle }} {{ item.rubikNumber }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="排序序号：" prop="moduleSerialNum">
          <a-input
            placeholder="请输入序号"
            type="number"
             max="99999"
             oninput="if(value.length>5)value=value.slice(0,5)"
            v-model="submit.moduleSerialNum"
            class="inputClass"
          />
        </a-form-model-item>

        <a-form-model-item label="状态：" prop="moduleState" style="padding-left: 6%">
          <a-select
            v-model="submit.moduleState"
            class="inputClass"
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option v-for="option in state" :key="option.id" :value="option.id">{{
              option.value
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div style="margin-left: 70%">
        <a-button type="primary" class="footerButton" @click="addModule"> 提交 </a-button>
        <a-button @click="cancel">返回</a-button>
      </div>
    </a-modal>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import { mapState } from "vuex";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import DSTable from "@/components/DSComponents/DSTable";
import debounce from "lodash/debounce";
import {
  modularProjectList,
  addModuleBinding,
  editModuleBinding,
  projectList,
  selectModularInfoList,
  selectModularProjectById,
  deleteModularProject,
  enableOrDisable,
  rubikByTitleNumber,
} from "@/api/modularProjectLiist";

export default {
  name: "moduleBindingManage",
  components: {
    DSTable,
    ConfirmBoxModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    let checkPriority = (rule, value, callback) => {
      // 校验优先级
      if (Number(value) > 0) {
        return callback();
      }
      // 不合法
      callback(new Error("排序序号最小值为1"));
    };
    return {
      // 魔方选择下拉框是否显示
      rubikcubeVisble: false,
      IMG_API: IMG_API,
      // 图片loading
      loading: false,
      // 项目id
      projectChangeId: "",
      params: {
        // 项目名称
        projectName: undefined,
        // 状态
        state: undefined,
        // 模块名称
        moduleName: undefined,
      },
      // 魔方编号
      rubikNumber: [],
      title: "",
      id: "",
      // 状态
      state: [
        {
          id: 0,
          value: "禁用",
        },
        {
          id: 1,
          value: "启用",
        },
      ],
      // 模块名称
      moduleName: [],
      // 项目名称
      projectName: [],
      submit: {
        // 项目名称
        projectId: undefined,
        // 模块名称
        modularId: undefined,
        // 魔方选择
        rubikId: "",
        // 排序
        moduleSerialNum: "",
        // 状态
        moduleState: undefined,
      },
      rules: {
        // 项目名称
        projectId: [{ required: true, message: "请填写完整信息", trigger: "change" }],
        // 模块名称
        modularId: [{ required: true, message: "请填写完整信息", trigger: "change" }],
        // 魔方选择
        rubikId: [{ required: true, message: "请填写完整信息", trigger: "change" }],
        // 排序
        moduleSerialNum: [
          { required: true, message: "请填写完整信息", trigger: "blur" },
          { required: false, pattern: new RegExp(/^(0|\+?[1-9][0-9]*)$/, "g"), message: "只能输入数字" },
          { validator: checkPriority, trigger: "blur" },
        ],
        // 状态
        moduleState: [{ required: true, message: "请填写完整信息", trigger: "change" }],
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "项目名称",
            dataIndex: "projectName",
            key: "projectName",
          },
          {
            title: "模块名称",
            dataIndex: "moduleTitle",
            key: "moduleTitle",
          },
          {
            title: "排序",
            dataIndex: "moduleSerialNum",
            key: "moduleSerialNum",
          },
          {
            title: "状态",
            dataIndex: "moduleState",
            key: "moduleState",
            customRender(text) {
              return text == 0 ? "禁用" : text == 1 ? "启用" : "-";
            },
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      visibleFloor: false,
    };
  },
  mounted() {
    this.projectList();
    this.searchFormList();
    this.selectModularInfoList();
    this.rubikByTitleNumber();
  },
  methods: {
    // 魔方编号下拉框
    async rubikByTitleNumber() {
      const res = await rubikByTitleNumber();
      if (res.code == 200) {
        this.rubikNumber = res.data;
      }
    },

    // 模块名称下拉框选择
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    // 获取详情
    async selectModularProjectById() {
      const res = await selectModularProjectById({ id: this.id });
      if (res.code == 200) {
        this.submit = {
          // 项目名称
          projectId: res.data.projectId,
          // 模块名称
          modularId: res.data.modularId,
          // 魔方选择
          rubikId: res.data.rubikId,
          // 排序
          moduleSerialNum: res.data.moduleSerialNum,
          // 状态
          moduleState: res.data.moduleState,
        };
        if (res.data.rubikId) {
          this.rubikcubeVisble = true;
        } else {
          this.rubikcubeVisble = false;
        }
      }
    },

    // 项目名称拉下选择
    handleChangeProject(value) {
      this.projectChangeId = value;
      this.selectModularInfoList();
    },

    // 模块名称下拉
    async selectModularInfoList() {
      const res = await selectModularInfoList({ id: this.projectChangeId });
      if (res.code == 200) {
        this.moduleName = res.data;
      }
    },

    // 项目下拉框
    async projectList() {
      const res = await projectList();
      this.projectName = res.data.items;
    },

    // 处理获取列表时数据
    searchFormList() {
      let obj = {};
      obj.projectName = this.params.projectName == undefined ? null : this.params.projectName;
      obj.state = this.params.state == undefined ? null : this.params.state;
      obj.moduleName = this.params.moduleName == undefined ? null : this.params.moduleName;
      if (obj.projectName !== null || obj.state !== null || obj.moduleName !== null) {
        obj = obj.projectName + "," + obj.state + "," + obj.moduleName;
        this.modularProjectList(this.table.pagination.pageSize, this.table.pagination.current - 1, obj);
      } else {
        obj = "null" + "," + "null" + "," + "null";
        this.modularProjectList(this.table.pagination.pageSize, this.table.pagination.current - 1, obj);
      }
    },

    // 模块名称下拉框选择
    handleChange(value) {
      const module = this.moduleName.filter((item) => {
        if (item.id === value) return item;
      })[0];
      if (module.moduleType === "2004") {
        this.rubikcubeVisble = true;
      } else {
        this.submit.rubikId = "";
        this.rubikcubeVisble = false;
      }
    },

    // 获取列表
    async modularProjectList(page, size, data) {
      const res = await modularProjectList(page, size, data);
      if (res.code == 200) {
        this.table.dataInfo = res.data.items;
        this.table.pagination.total = res.data.total;
      }
    },

    // 新建模块
    add() {
      this.title = "添加";
      (this.submit = {
        // 项目名称
        projectId: undefined,
        // 模块名称
        modularId: undefined,
        // 魔方选择
        rubikId: "",
        // 排序
        moduleSerialNum: "",
        // 状态
        moduleState: undefined,
      }),
        (this.visibleFloor = true);
      this.rubikcubeVisble = false;
    },

    //添加
    addModule: debounce(function () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.title === "添加") {
            const res = await addModuleBinding(this.submit);
            if (res.code === "200") {
              this.$message.success("新建成功");
              this.searchFormList();
              this.$refs.ruleForm.resetFields();
              this.visibleFloor = false;
            } else {
              this.$message.warning(res.msg);
            }
          } else {
            let obj = {
              ...this.submit,
              id: this.id,
            };
            const res = await editModuleBinding(obj);
            if (res.code === "200") {
              this.$message.success("编辑成功");
              this.visibleFloor = false;
              this.$refs.ruleForm.resetFields();
              this.table.pagination.current = 1;
              this.searchFormList();
            } else {
              this.$message.warning(res.msg);
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, 500),

    //禁用启用
    async disableorEnabled(text) {
      const confirmData = {
        title: "提示",
        content: `是否确认${text.moduleState == 1 ? "禁用" : "启用"}？`,
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      let obj = {
        id: text.id,
        moduleState: text.moduleState,
      };
      obj.moduleState == 1 ? (obj.moduleState = 0) : (obj.moduleState = 1);
      await this.$refs.confirmBoxModule.show(confirmData);
      this.modularDisable(obj);
    },

    // 启用禁用
    async modularDisable(data) {
      const res = await enableOrDisable(data);
      if (res.code == "200") {
        this.$message.success(`${data.moduleState == 0 ? "禁用" : "启用"}成功`);
        this.table.pagination.current = 1;
        this.searchFormList();
      } else {
        this.$message.error(res.msg);
      }
    },

    // 删除
    async deleteById(record) {
      const confirmData = {
        title: "提示",
        content: "是否确认删除？确认后关联数据将被删除",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.delet(record.id);
    },

    // 删除
    async delet(id) {
      let data = {};
      data.id = id;
      const res = await deleteModularProject(data);
      if (res.code === "200") {
        this.$message.success("删除成功");
        this.table.pagination.current = 1;
        this.searchFormList();
      } else {
        this.$message.error(res.msg);
      }
    },

    //查询
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    //重置
    resetSearchForm() {
      (this.table.pagination.current = 1),
        (this.table.pagination.pageSize = 10),
        (this.params = {
          projectName: undefined,
          moduleName: undefined,
          state: undefined,
        });
      this.searchFormList();
    },

    //编辑
    async edit(record) {
      this.title = "编辑";
      this.visibleFloor = true;
      this.id = record.id;
      this.selectModularProjectById();
    },

    // 关闭蒙层的操作(取消校验)
    async cancel() {
      if (
        this.submit.projectId !== undefined ||
        this.submit.modularId !== undefined ||
        this.submit.rubikId !== "" ||
        this.submit.moduleSerialNum !== "" ||
        this.submit.moduleState !== undefined
      ) {
        const confirmData = {
          title: "提示",
          content: "是否确认返回？返回后，编辑数据将不会被保存。",
          type: ConfirmConfig.CONFIRM_TYPE_WARNING,
        };

        await this.$refs.confirmBoxModule.show(confirmData);
        this.visibleFloor = false;
        this.rubikcubeVisble = false;
        this.$refs.ruleForm.resetFields();
      } else {
        this.visibleFloor = false;
        this.$refs.ruleForm.resetFields();
      }
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
  },
};
</script>

<style lang="scss" scoped>
.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.searchInput {
  width: 200px;
}
.inputClass {
  width: 280px;
}
.inputColor {
  color: #bdbdbd !important;
}
</style>
